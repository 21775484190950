import './Header.css';
import { useRef } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link, animateScroll as scroll } from 'react-scroll';

function Header() {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle('responsive_nav');
  };

  return (
    <header>
      <h3>Posnyák Ágnes</h3>
      <nav ref={navRef}>
        <Link to="home" smooth={true} duration={300} onClick={showNavbar}>Főoldal</Link>
        <Link to="about" smooth={true} duration={500} onClick={showNavbar}>Rólam</Link>
        <Link to="services" smooth={true} duration={800} onClick={showNavbar}>Szolgáltatások</Link>
        <Link to="contact" smooth={true} duration={1200} onClick={showNavbar}>Kapcsolat</Link>
        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </button>
      </nav>
      <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
      </button>
    </header>
  );
}

export default Header;
