import './Contact.css';

import React, { useState, useEffect,useRef } from "react";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import emailjs from 'emailjs-com';
  
  function Contact() {
    const formRef = useRef();
    const [statusText,setStatusText] = useState("Vagy írj nekem, ha bármilyen kérdésed van.")
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
  
    const handleSubmit = (event) => {
      event.preventDefault();
      emailjs.sendForm(
        'service_3a746u9', // replace with your service ID
        'template_1ovy7pl', // replace with your template ID
        formRef.current,
        'L__HdV7VgBS9V4LBQ'
      )
        .then((response) => {
          console.log('Email sent successfully:', response);
          setStatusText("Sikeresen elküldted nekem az emailt!")
        })
        .catch((error) => {
          console.error('Error sending email:', error);
          setStatusText(error)
        });
  
      // You can reset the form state if needed
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    };
  
    const handleChange = (event) => {
      const { name, value } = event.target; 
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value
      }));
    };
  
  
    return (
      <div id="contact" className="contact">
        <div className="left-form" style={{ color: '#36454F' }}>
        <p style={{ fontSize:'6rem' ,fontWeight:'400'}}>Kapcsolat</p>
        <p style={{ fontSize:'4rem' ,marginTop:'5rem'}}>Posnyák Ágnes</p>
        <div className='email-div'>
          <p>2119 Pécel Szondy utca 15</p>
        </div>
        <div className='email-div' >
          <p>posnyak.agi@gmail.com</p>
        </div>
        <div >
          <p style={{marginTop:'1rem',width:"16rem"}}>Időpont egyeztetés esetén minden nap 15-17 között, illetve 20 órától lehetséges.</p>
        </div>
        <div className='email-div' >
          <p>+36 20 911 7284</p>
        </div>
        <p style={{ fontSize:'4rem' ,marginTop:'2rem'}}>Nyitvatartás</p>
        <div className='opening-dates-div'>
          <p>Hétfő 8-14 18-20</p>
        </div>
        <div className='opening-dates-div' >
          <p>Kedd 8-14 18-20</p>
        </div>
        <div className='opening-dates-div' >
          <p>Szerda 7-16 18-20</p>
        </div>
        <div className='opening-dates-div' >
          <p>Csütörtök 7-14 18-20</p>
        </div>
        <div className='opening-dates-div' >
          <p style={{marginBottom:'4rem'}}>Péntek 7-14 18-20</p>
        </div>
      </div>
      <div className="form-container">
      <Form ref={formRef} className="form-section" style={{ color: '#36454F' }} onSubmit={handleSubmit}>
        <Form.Group controlId="formTitle">
          <Form.Label className="form-title-1">Kérd egyedi ajánlatomat!</Form.Label>
          <br></br>
          <br></br>
          <Form.Label className="form-title-2">{statusText}</Form.Label><br></br><br></br>
        </Form.Group>
        <div className="my-5"></div>
        <Form.Group controlId="formName">
          <Form.Label style={{marginTop:'10rem'}}>Név (*)</Form.Label><br></br>
          <Form.Control
          style={{ width:'20rem',height:'2rem',marginBottom:'2rem'}}
            type="text"
            name="name"
            placeholder=""
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <div className="my-4"></div>
        <Form.Group controlId="formEmail">
          <Form.Label>Email (*)</Form.Label><br></br>
          <Form.Control
          style={{ width:'20rem',height:'2rem',marginBottom:'2rem'}}
            type="email"
            name="email"
            placeholder=""
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>
  
        <div className="my-4"></div>
        <Form.Group controlId="formSubject">
          <Form.Label>Tárgy</Form.Label><br></br>
          <Form.Control
          style={{ width:'20rem',height:'2rem',marginBottom:'2rem'}}
            type="text"
            name="subject"
            placeholder=""
            value={formData.subject}
            onChange={handleChange}
          />
        </Form.Group>
  
        <div className="my-4"></div>
        <Form.Group controlId="formMessage">
          <Form.Label>Üzenet</Form.Label><br></br>
          <Form.Control
          style={{ width:'20rem',height:'5rem',marginBottom:'2rem'}}
            as="textarea"
            rows={3}
            name="message"
            placeholder=""
            value={formData.message}
            onChange={handleChange}
          />
        </Form.Group>
  
        <div className="my-5"></div>
        <Button variant="danger" type="submit" className="custom-submit-button" onClick={handleSubmit}>
          Küldés
        </Button>
      </Form>
      </div>
  
      </div>
    );
  }
  
  export default Contact;
  