import './Footer.css'

function Footer(){
    return(
        <footer className="footer">
      <p>&copy; {new Date().getFullYear()} Posnyák Ágnes. All rights reserved.</p>
    </footer>
        );
}

export default Footer;