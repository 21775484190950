import './About.css';
import { motion } from "framer-motion";
import profilePicture from "./agiprofil.jpg";

function About(){
    return(
        <div id="about" className="container">
            <div className='textContainer'>
                <p>ÜDVÖZÖLLEK AZ ÉRINTÉS VILÁGÁBAN</p>
                <h1 style={{marginBottom:'1rem'}}>Szia, Posnyák Ágnes vagyok</h1>
                <p style={{lineHeight: 2}}>{new Date().getFullYear()-2001} éve ennek a csodálatos hivatásomnak élhetek, amiben mindennap megtalálom a varázslatot. A tanulásomat 1999-ben kezdtem és azóta is folyamatosan képzem magamat. Törekszem a legjobb tudásom szerint tényleg személyre szabottan dolgozni, így kéz a kézben a vendéggel együtt a felépülés felé tartva. Ötvözve az orvostudomány által kínált lehetőségeket a tudásommal össze egyeztetve megtalálni a legjobb megoldást a minél hosszabb aktív és kiegyensúlyozott életminőség javítására. Ennek érdekében több kollégával is egymáshoz elirányítva specifikusan szoktam dolgozni.  Hálás vagyok a sorsnak, hogy azt csinálhatom, amit szeretek.</p>
            </div>
            <div className='imgContainer'>
                <img className='profilePicture' src={profilePicture} />
            </div>
        </div>
        );
}
export default About;