import './App.css';
import Header from './Header/Header';
import Home from './Home/Home';
import About from './About/About';
import Services from './Services/Services';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';


function App() {
  return (
    <div className="App">
          <Header/>
          <Home id="home" />
          <About id="about" />
          <Services id="services" />
          <Contact id="contact" />
          <Footer/>
    </div>
  );
}

export default App;
