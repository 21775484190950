import './Home.css'
import { motion, useScroll, useAnimation } from "framer-motion";
import React, { useEffect, useState } from "react";
import profilePicture from "../About/pngegg.png";
import AnimatedScrollComponent from './AnimatedComponent';
import AnimatedPicture from './AnimatedPicture';


function Home() {
  const { scrollYProgress } = useScroll();

  const ER = ['B', 'E', 'M', 'E', 'R'];
  const IN = ['R', 'A', 'K', 'Á', 'S'];
  const T = ['E', 'R', 'Á', 'P', 'I', 'A'];
  const E = ['R', 'Z', 'É', 'S'];
  const S = ['H', 'I', 'A', 'T', 'S', 'U'];
//<img className='profilePicture' src={profilePicture} style={{height:'5rem',width:'4.2rem',marginTop:'3rem'}}/>
    return (
      <div id="home" className="Home">
        <div className='er'>
            ÉR
            <div className='er-sub'><AnimatedScrollComponent letters={ER} /></div>
        </div>
        ~
        <div className='in'>
          IN
          <div className='in-sub'><AnimatedScrollComponent letters={IN} /></div>
        </div>
        ~
        <div className='t'>
          T
          <AnimatedScrollComponent letters={T} />
        </div>
        ~
        <div className='e'>
          É
          <AnimatedScrollComponent letters={E} />
        </div>
        ~
        <div className='s'>
          S
          <AnimatedScrollComponent letters={S} />
        </div>
        {/* <div className='img-container'><AnimatedPicture imageSrc={profilePicture} /></div>  */}
    
      </div>
    );
}

export default Home;
