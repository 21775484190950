import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

function AnimatedScrollComponent({ letters }) {
  const [visibleLetters, setVisibleLetters] = useState(0);
  const controls = useAnimation();
  const scrollDistance = 12; // Set the distance to scroll for each letter reveal

  const handleScroll = () => {
    const scrollY = window.scrollY || window.pageYOffset;

    const lettersToShow = Math.floor((scrollY / scrollDistance)-1) + 1;
    setVisibleLetters(Math.min(lettersToShow, letters.length));
  };

  useEffect(() => {
    controls.start({ opacity: 1, y: 0 }); // Initial animation state

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [controls]);

  return (
    <div>
      <motion.div className='animated-sub' style={{ display: 'flex', flexDirection: 'column' }}>
        {letters.map((letter, index) => (
          <motion.span
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={visibleLetters > index ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.5 }} // Adjust the animation duration
            style={{ marginBottom: 10 }}
          >
            {letter}
          </motion.span>
        ))}
      </motion.div>
    </div>
  );
}


export default AnimatedScrollComponent;